import React from 'react'

export default function Ribbon() {
  
    return (
        <>
            <div id="practice-section" className="container-fluid ribbon-bg"> 
                <div className="container ribbon-container">
                    <div className="row">
                        <div className="col-10 col-md-11 col-lg-12 col-xl-10 mx-auto">
                            <p className="ribbon-title text-center show-on-scroll-bottom">¿Tienes dudas? Permítenos asesorarte</p>
                            <p className="ribbon-subtitle text-center show-on-scroll-bottom">Confía en Nosotros para Obtener un Análisis Detallado y Personalizado de tu Situación Legal, y Descubre la Mejor Estrategia para tu Caso.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}