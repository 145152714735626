import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faWhatsapp } from '@fortawesome/free-brands-svg-icons';

const WhatsAppButton = () => {
    const phoneNumber = '+50768481411'; // Replace with your WhatsApp number
    const message = 'Hola, me interesa conocer más de los servicios legales que ofrece.'; // Default message to send on click

    const handleClick = () => {
        window.open(`https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`, '_blank');
    };

    return (
        <div className="whatsapp-button" onClick={handleClick}>
            <FontAwesomeIcon icon={faWhatsapp} size="2x" color="#fff" />
        </div>
    );
};

export default WhatsAppButton;