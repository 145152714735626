import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import logo from "../assets/images/logo-blanco.svg";





export default function Hero() {

const [formData, setFormData] = useState({
        name: '',
        email: '',
        message: '',
        service: '',
        phone:''
});
  
const [status, setStatus] = useState('');
const [fadeOut, setFadeOut] = useState(false);
  
const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
};
    
const handleSubmit = (e) => {
        e.preventDefault();
    
        // Replace with your EmailJS service details
        emailjs.sendForm('service_0nvmjo7', 'template_frm4hll', e.target, 'xgMxWVgoot3iMKorg')
          .then((result) => {
            setStatus('Mensaje enviado con exito!');
            setFadeOut(false);

            // After 3 seconds, trigger fade-out effect
            setTimeout(() => {
                setFadeOut(true);
            }, 3000);
            setFormData({ name: '', email: '', message: '' });
          }, (error) => {

            setStatus('No se pudo enviar mensaje, intenta nuevamente..');
            setFadeOut(false);

            // After 3 seconds, trigger fade-out effect
            setTimeout(() => {
                setFadeOut(true);
            }, 3000);
          });
};
  
    return (
        <>
            <div id="hero-section" className="container-fluid hero-bg">

                {/* Nav Bar */}
                    <nav className="navbar navbar-expand-lg">

                        <div className="logo-section">
                            <a href="#"><img className="navbar-logo" src={logo} alt="JR Estudio Jurídico"></img></a>
                        </div>

                        <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"><i className="fa-solid fa-bars my-burger-menu"></i></span>
                        </button>

                        <div className="collapse navbar-collapse" id="navbarSupportedContent">
                            <ul className="navbar-nav">
                            <li className="nav-item my-auto active">
                                <a className="menu-link" href="#hero-section">Inicio<span className="sr-only"></span></a>
                            </li>
                            <li className="nav-item my-auto">
                                <a className="menu-link" href="#about-section">Nosotros</a>
                            </li> 
                            <li className="nav-item my-auto">
                                <a className="menu-link" href="#practice-section">Áreas de Práctica</a>
                            </li>
                            <li className="nav-item my-auto">
                                <a className="menu-link" href="#team-section">Equipo</a>
                            </li>
                            <li className="nav-item my-auto">
                                <a className="menu-link" href="#address-section">Nuestras Oficinas</a>
                            </li>
                            <li className="nav-item my-auto">
                                <a className="contact-menu-link" href="https://wa.me/50768481411?text=Hola,%20me%20interesa%20conocer%20más%20de%20los%20servicios%20legales%20que%20ofrece." target="_blank">Contáctanos</a>
                            </li> 
                            </ul>
                        </div>
                    </nav>

                {/* Contact Form */}
                    <div className="container form-section">
                        <div className="row form-section-row">

                            <div className="col-12 col-sm-11 col-md-9 col-lg-8 my-form-title-section">
                                <p className="hero-title show-on-scroll-bottom">Soluciones Legales a tu Alcance</p>
                                <p className="show-on-scroll-bottom hero-subtitle">Orientación legal de expertos comprometidos a encontrar las mejores soluciones a tus necesidades. ¡Comienza ahora y obtén la solución legal que mereces!</p>
                            </div>

                            <div className="col-12 col-sm-11 col-md-9 col-lg-6 col-xl-5 myform-bg show-on-scroll-bottom">
                                <form className="needs-validation" id="newsletterForm" onSubmit={handleSubmit} encType="multipart/form-data">
                                    <div className="row">
                                    <div className="col-12 col-md-6 mx-auto">
                                        <div className="form-group">
                                            <input type="text" 
                                            className="form-control myform-input" 
                                            name="name" 
                                            id="exampleInputNombre1" 
                                            placeholder="Nombre Completo"
                                            value={formData.name}
                                            onChange={handleChange} 
                                            required /> 
                                        </div>
                                    </div>

                                    <div className="col-12 col-md-6 mx-auto">
                                        <div className="form-group">
                                            <input type="phone" className="form-control myform-input" name="phone" placeholder="Teléfono" 
                                            value={formData.phone}
                                            onChange={handleChange} 
                                            required />
                                        </div>
                                    </div>

                                    <div className="col-12 mx-auto">
                                        <div className="form-group">
                                            <input type="email" className="form-control myform-input" id="email" name="email" aria-describedby="emailHelp" placeholder="Correo Electrónico" 
                                            value={formData.email}
                                            onChange={handleChange}
                                            required />
                                        </div>
                                    </div>

                                    <div className="col-12 mx-auto">
                                        <div className="form-group">
                                            <select className="form-control myform-input" name="service" 
                                            defaultValue={formData.service}
                                            onChange={handleChange}
                                            required>
                                                <option disabled>Selecciona el área en la que necesitas ayuda</option>
                                                <option>Contratos</option>
                                                <option>Familia</option>
                                                <option>Laboral</option>
                                                <option>Migración</option>
                                                <option>Obtención de Permisos o Licencias</option>
                                                <option>Procesos Civiles</option>
                                                <option>Procesos Penales</option>
                                                <option>Propiedad Intelectual</option>
                                                <option>Sucesiones - Testamentos</option>
                                                <option>Otros</option>
                                            </select>
                                        </div>
                                    </div>

                                    <div className="col-12 mx-auto">
                                        <div className="form-group">
                                            <textarea className="form-control myform-input message-input" id="exampleFormControlTextarea1" name="message" rows="3" placeholder="Mensaje" 
                                            value={formData.message}
                                            onChange={handleChange}
                                            required></textarea>
                                        </div>
                                    </div>

                                    <div className="d-flex justify-content-end">
                                        <button type="submit" name="save" className="btn mysubmit-button">Contáctanos</button>
                                        <input type="hidden" name="MAX_FILE_SIZE" value="100000" />
                                    </div>
                                    </div>
                                </form>
                                {status && <p className={`form__status ${fadeOut ? 'fade-out' : ''}`}>
                    {status}
                </p>}
                            </div>
                        
                        </div>
                    </div>
                    {/* <script>
                        $('#newsletterForm').submit(function(event) {
                            event.preventDefault();
                            var email = $('#email').val();
                    
                            grecaptcha.ready(function() {
                                grecaptcha.execute('6LevYUQaAAAAAB4xhJ4-JEaLUxx8nMfFlEQV5-H1', {action: 'subscribe_newsletter'}).then(function(token) {
                                    $('#newsletterForm').prepend('<input type="hidden" name="token" value="' + token + '">');
                                    $('#newsletterForm').prepend('<input type="hidden" name="action" value="subscribe_newsletter">');
                                    $('#newsletterForm').unbind('submit').submit();
                                });;
                            });
                    });
                    </script> */}


                {/* Down Button */}
                <a href="#" className="btn mydown-arrow-button mx-auto">
                    <i className="fas fa-arrow-down"></i>
                </a>

            </div>      
        </>
    )
}
