import React from 'react'
// import {APIProvider, Map, MapCameraChangedEvent} from '@vis.gl/react-google-maps';

export default function Address() {


    
    return (
        
        <>
            <div id="address-section" className="container-fluid address-bg"> 
                <div className="container">
                    {/* Title */}
                    <div className="row">
                        <div className="col-10 col-md-9 col-lg-7 mx-auto">
                            <p className="section-title text-center show-on-scroll-bottom">Nuestras Oficinas</p>
                            <p className="section-subtitle text-center show-on-scroll-bottom">Cuidad de Panamá, Avenida Balboa, Edificio Rali Business Center, nivel 3, oficina 305</p>
                        </div>
                    </div>
                </div>

                {/* Map */}
                <div className="container d-flex justify-content-center">
                    <div className="map-wrapper">
                        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d246.30992399446075!2d-79.51910461902486!3d8.975939964878105!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8faca5fc301fd4ab%3A0x42e31182d58d8c11!2sRali%20Business%20Center!5e0!3m2!1ses-419!2spa!4v1724219591409!5m2!1ses-419!2spa" width="800" height="400" className="map-class" allowFullScreen="" loading="lazy" referrerPolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div> 

            </div>
        </>
    )
}