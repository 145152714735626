import React from 'react'

export default function Team() {
  
    return (
        <>
            <div id="team-section" className="container-fluid px-0"> 

                {/* Title */}
                <div className="container">
                    <div className="row">
                        <div className="col-10 col-md-9 col-lg-9 col-xl-7 mx-auto">
                            <p className="section-title text-center show-on-scroll-bottom">Equipo</p>
                            <p className="section-subtitle text-center show-on-scroll-bottom">Conoce a nuestro equipo de abogados, profesionales dedicados a encontrar soluciones efectivas para tus desafíos legales.</p>
                        </div>
                    </div>
                </div>

                {/* Content */}
                <div className="team-colors d-flex">
                    <div className="team-blue"></div>
                    <div className="team-img-blue"></div>
                </div>
                <div className="container team-img-text">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-7 d-flex">
                            {/* <div className="team-blue"></div> */}
                            <div className="team-text">
                                <p>Licenciado Josué Reid</p>
                                <p>Licenciado en Derecho y Ciencias Políticas</p>
                                <p>Nos distinguimos por nuestro enfoque personalizado y atención al detalle en cada caso que manejamos. Con un compromiso firme con la excelencia y la transparencia, trabajamos mano a mano con nuestros clientes para lograr resultados exitosos y satisfactorios. En JR Estudio Jurídico, no solo ofrecemos asesoramiento legal, sino también un acompañamiento cercano y un ambiente de confianza donde tus preocupaciones y objetivos son nuestra principal prioridad. Confía en nosotros para obtener la representación legal que mereces y navega por tus desafíos legales con confianza y tranquilidad.</p>
                                <div className="">
                                    <a className="btn myteam-button" href="https://wa.me/50768481411?text=Hola,%20me%20interesa%20conocer%20más%20de%20los%20servicios%20legales%20que%20ofrece." target="_blank">Contáctanos</a>
                                </div>
                            </div>
                        </div>
                    
                        <div className="col-11 col-md-12 col-lg-5 team-content d-flex justify-content-end show-on-scroll-bottom">
                            <div className="team-img"></div>
                        </div>
                    </div>
                </div>
                
            </div>
        </>
    )
}