import React, { useState } from 'react'
import emailjs from 'emailjs-com';
import logo from "../assets/images/logo.svg"

export default function Footer() {

    const [formData, setFormData] = useState({
        email: '',
        service: '',
    });
    
    const [status, setStatus] = useState('');
    
    const handleChange = (e) => {
            setFormData({ ...formData, [e.target.name]: e.target.value });
    };
        
    const handleSubmit = (e) => {
            e.preventDefault();
            // Replace with your EmailJS service details
            emailjs.sendForm('service_0nvmjo7', 'template_frm4hll', e.target, 'xgMxWVgoot3iMKorg')
            .then((result) => {
                setStatus('Solicitud enviada con exito!');
                setFormData({email: '', service: ''});
            }, (error) => {
                setStatus('No se pudo enviar la solicitud, intenta nuevamente..');
            });
    };
  
    return (
        <footer id="footer" className="text-lg-start footer-styling">
        <div className="container mb-4">
            <div className="row d-flex justify-content-center justify-content-md-center">

                <div className="col-xl-7 col-lg-6 col-md-5 text-lg-left mb-5 ">
                    <a className="myfooter-logo-link" href="./index.php"><img src={logo} className="myfooter-logo"></img></a>
                    <div className="d-flex justify-content-lg-start justify-content-md-start justify-content-center social-media-socials-box">
                        <a className="social-media-icons" href="#" target="_blank"><i className="fa-brands fa-instagram footer-socials"></i></a>
                        <a className="social-media-icons" href="#" target="_blank"><i className="fa-brands fa-tiktok footer-socials"></i></a>
                        <a className="social-media-icons" href="https://wa.me/50768481411" target="_blank"><i className="fa-brands fa-whatsapp footer-socials"></i></a>
                    </div>
                </div>

                <div className="col-xl-2 col-lg-3 col-md-2 col-sm-7 col-9 text-xxl-left text-xl-left text-lg-left footer-menu-links-text">
                    <ul className="myfooter-menu-links">
                    <li className="list-unstyled footer-menu-links mb-3"><a href="#hero-section">Inicio</a></li>
                    <li className="list-unstyled footer-menu-links mb-3"><a href="#about-section">Equipo</a></li>
                    <li className="list-unstyled footer-menu-links mb-3"><a href="#practice-section">Áreas de Práctica</a></li>
                    <li className="list-unstyled footer-menu-links mb-3"><a href="#team-section">Equipo</a></li>
                    <li className="list-unstyled footer-menu-links mb-3"><a href="#address-section">Nuestras Oficinas</a></li>
                    <li className="list-unstyled footer-menu-links"><a href="https://wa.me/50768481411?text=Hola,%20me%20interesa%20conocer%20más%20de%20los%20servicios%20legales%20que%20ofrece.">Contáctanos</a></li>
                    </ul>
                </div>

                <div className="col-xl-3 col-lg-3 col-md-5 col-sm-7 col-9 text-lg-left text-sm-center text-center">
                    <p className="footer-subtitle">Necesito más información</p>
                        <form className="needs-validation myform-bg" id="newsletterForm" onSubmit={handleSubmit} encType="multipart/form-data">
                            <div className="form-group">
                                <input type="email" className="form-control myfooterform-input" id="email" name="email" aria-describedby="emailHelp" placeholder="Correo Electrónico" 
                                value={formData.email}
                                onChange={handleChange}
                                required />
                            </div>
                            <div className="form-group">
                                <div className="custom-select-wrapper">
                                    <select className="form-control myfooterform-input" name="service" 
                                        defaultValue={formData.service}
                                        onChange={handleChange}
                                        required>
                                        <option>Área de Práctica</option>
                                        <option>Contratos</option>
                                        <option>Familia</option>
                                        <option>Laboral</option>
                                        <option>Migración</option>
                                        <option>Obtención de Permisos o Licencias</option>
                                        <option>Procesos Civiles</option>
                                        <option>Procesos Penales</option>
                                        <option>Propiedad Intelectual</option>
                                        <option>Sucesiones - Testamentos</option>
                                        <option>Otros</option>
                                    </select>
                                    <i className="fas fa-chevron-down"></i>
                                </div>
                            </div>                             
                            <div className="d-flex">
                                <button type="submit" name="save" className="btn myfootersubmit-button">Contáctarme</button>
                                <input type="hidden" name="MAX_FILE_SIZE" value="100000" />
                            </div>
                        </form>
                        {status && <p>{status}</p>}
                </div>
            </div>

        </div>
        <div className="row footer-disclaimer">
                <div className="col-lg-12 col-md-12 text-center">
                    <p>JR Estudio Jurídico</p>
                </div>
                <div className="col-lg-12 col-md-12 text-center">
                    <p><a href="tel:+50768481411">+507 6848-1411</a></p>
                </div>
                <div className="col-lg-12 col-md-12 text-center">
                    <p>consultas@jrestudiojuridico.com</p>
                </div>
                <div className="col-lg-12 col-md-12 text-center">
                    <p>Cuidad de Panamá, Avenida Balboa, Edificio Rali Business Center, nivel 3, oficina 305</p>
                </div>
        </div>

        </footer>
    )
}