import React from "react"

import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import "./style.css"
import "./mediaqueries.css"
import Hero from "./components/Hero"
import About from "./components/About"
import Practice from "./components/Practice"
import Team from "./components/Team"
import Footer from "./components/Footer"
import Ribbon from "./components/Ribbon"
import Address from "./components/Address"
import WhatsAppButton from './components/WhatsAppButton'; // Adjust the path as needed





export default function App() {

    return (
        <>
            <Hero />
            <About />
            <Practice />
            <Team />
            <Ribbon />
            <Address />
            <Footer />
            <WhatsAppButton />
        </>
    )
}

