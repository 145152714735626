import React from 'react'

export default function About() {
  
    return (
        <>
            <div id="about-section" className="container-fluid about-bg"> 
                <div className="container">
                    {/* Title */}
                    <div className="row">
                        <div className="col-10 col-md-9 col-lg-9 col-xl-6 mx-auto">
                            <p className="section-title text-center show-on-scroll-bottom">Nosotros</p>
                            <p className="section-subtitle text-center show-on-scroll-bottom">Comprometidos en brindarte asesoría confiable y resultados exitosos en cada caso</p>
                        </div>
                    </div>
                </div>

                {/* Content */}
                <div className="about-img-colors d-flex">
                    <div className="about-img-red d-flex"></div>
                    <div className="about-img-blue d-flex"></div>
                </div>    
                <div className="container about-img-text">
                    <div className="row">
                        <div className="col-12 col-md-12 col-lg-4 col-xl-5 d-flex about-content">
                            <div className="about-img"></div>
                        </div>
                    
                        <div className="col-12 col-md-12 col-lg-8 col-xl-7 about-text show-on-scroll-bottom">
                            <p>Nos distinguimos por nuestro enfoque altamente personalizado y nuestra meticulosa atención al detalle en cada caso que manejamos, asegurando que cada aspecto sea cuidadosamente considerado. Con un compromiso firme con la excelencia y la transparencia, trabajamos en estrecha colaboración con nuestros clientes para lograr resultados no solo exitosos, sino también completamente satisfactorios.<br></br><br></br>En JR Estudio Jurídico, no solo ofrecemos asesoramiento legal, sino que también brindamos un acompañamiento cercano y constante. Aquí, tus preocupaciones y objetivos son nuestra principal prioridad, y nos dedicamos a escucharte y entenderte a fondo. Confía en nosotros para obtener la representación legal que mereces y navega por tus desafíos legales con la seguridad y tranquilidad.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}