import React from 'react'
import migracion from "../assets/images/SVG/migracion.svg"
import laboral from "../assets/images/SVG/laboral.svg"
import penal from "../assets/images/SVG/penal.svg"
import civil from "../assets/images/SVG/civil.svg"
import contratos from "../assets/images/SVG/contratos.svg"
import permisos from "../assets/images/SVG/permisos.svg"

export default function Practice() {
  
    return (
        <>
            <div id="practice-section" className="container-fluid practice-bg"> 
                <div className="container">
                    {/* Title */}
                    <div className="row">
                        <div className="col-10 col-md-9 col-lg-9 col-xl-6 mx-auto">
                            <p className="section-title-2 text-center show-on-scroll-bottom">Areas de Práctica</p>
                            <p className="section-subtitle-2 text-center show-on-scroll-bottom">Especialistas en distintas ramas del derecho, ofreciendo soluciones legales integrales y personalizadas.</p>
                        </div>
                    </div>

                    {/* Content */}
                    <div className="row justify-content-center">
                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-center show-on-scroll-bottom">
                            <div className="services-square clickable">
                                <div className="text-services-square">
                                    <img src={migracion} className="services-icons"></img>
                                    <p className="service-title">Migración</p>
                                    <p className="service-text">Contamos con un equipo de abogados experimentados en leyes migratorias, comprometidos en facilitar tu proceso de migración de manera eficiente y legalmente sólida.</p>
                                </div>
                            </div>
                        </div>
                
                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-center show-on-scroll-bottom">
                        <div className="services-square clickable">
                            <div className="text-services-square">
                            <img src={laboral} className="services-icons"></img>
                            <p className="service-title">Laboral</p>
                            <p className="service-text">Especialistas en leyes laborales, brindamos representación en casos de reclamos laborales, negociaciones sindicales y cumplimiento normativo empresarial.</p>
                            </div>
                        </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-center show-on-scroll-bottom">
                        <div className="services-square clickable">
                            <div className="text-services-square">
                            <img src={penal} className="services-icons"></img>
                            <p className="service-title">Penal</p>
                            <p className="service-text">Nuestro equipo de abogados penalistas cuenta con experiencia en juicios penales, negociaciones de acuerdos y recursos de apelación, garantizando una representación efectiva.</p>
                            </div>
                        </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-center show-on-scroll-bottom">
                        <div className="services-square clickable">
                            <div className="text-services-square">
                            <img src={civil} className="services-icons"></img>
                            <p className="service-title">Civil</p>
                            <p className="service-text">En JR Estudio Jurídico, nos dedicamos a proteger tus intereses en asuntos civiles, brindando asistencia legal eficaz y estratégica en cada caso.</p>
                            </div>
                        </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-center show-on-scroll-bottom">
                        <div className="services-square clickable">
                            <div className="text-services-square">
                            <img src={contratos} className="services-icons"></img>
                            <p className="service-title">Contratos</p>
                            <p className="service-text">Desde la revisión de términos contractuales hasta la representación en disputas contractuales, estamos aquí para brindarte el apoyo legal necesario.</p>
                            </div>
                        </div>
                        </div>

                        <div className="col-6 col-sm-6 col-md-6 col-lg-4 col-xl-3 text-center show-on-scroll-bottom">
                        <div className="services-square clickable">
                            <div className="text-services-square">
                            <img src={permisos} className="services-icons"></img>
                            <p className="service-title">Permisos</p>
                            <p className="service-text">Ofrecemos asesoramiento legal especializado en obtención y renovación de licencias y permisos comerciales, asegurando el cumplimiento de regulaciones y normativas.</p>
                            </div>
                        </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}